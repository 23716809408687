<template>
  <transition name="fade" appear>
    <div class="home">
      <div class="content" v-html="content"  > 
        <!-- <el-image :src='this.content'>
      </el-image>  -->
      </div>
    </div>
  </transition>
</template>

<script>
import { CHANGE_NAVINDEX } from "../../store/mutation-types";
export default {
  data() {
    return {
      // title: "公共管理学课程教学大纲",
      // time: "2022年2月14日",
      // url: "http://banktrain.pluss.cn/upload/file_202202240916941.pdf",
      content: "",
      type:'',
      // examSubclassCode:"",
      loading:true,
    };
  },
  methods: {
    //获得首页配置文件
    // getPortalConfig(){
    //   var getData={};
    //   getData.examKey = this.type;
    //   this.$api.home.getPortalConfig(getData).then((res) => {
    //     if (res.data.code = 200){
    //       this.content = res.data.data.examValue;
    //     }
    //   });
    // },
    // getExamdetail(){
    //   var getData={};
    //   getData.examSubclassCode = this.examSubclassCode;
    //   this.$api.exam.getExamDetail(getData).then((res) => {
    //     if (res.data.code = 200){
    //       this.content = res.data.data.content;
    //       this.loading=false
    //     }
    //   });
    // },
    //查看详情
    download() {
      window.open(this.url);
    },
  },
  created() {
    this.$store.commit(CHANGE_NAVINDEX, "0");
    this.type=this.$route.query.type;
    this.content=this.$route.query.content;
    // console.log("===>content",this.$route.query.content)
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 1140px;
  padding: 0 140px;
  margin-top: 45px;
}
.img{
  width: 100%;
}
.content{
    img {
        // width: 100%;
        // height: auto;
    }
}
</style>
